<template>
  <div class="page">
    <div class="main">
      <div class="title">系统消息</div>
      <el-row style="margin-top: 10px; min-height: 580px">
        <el-button type="primary" @click="handleAdd" size="mini"
          ><i class="el-icon-plus"> 新增</i></el-button
        >
        <el-table
          :header-cell-style="rowClass"
          :key="tables.tableKey"
          v-loading="tables.listLoading"
          :data="tables.tableData"
          border
          highlight-current-row
          style="width: 100%; margin-top: 20px"
          height="580"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          />
          <el-table-column
            prop="title"
            label="标题"
            width="80"
            show-overflow-tooltip
          />
          <el-table-column
            prop="content"
            label="内容"
            width="180"
            show-overflow-tooltip
          />
           <el-table-column
            prop="create_time"
            label="添加时间"
            width="180"
            show-overflow-tooltip
          />
          <el-table-column prop="order_status" label="状态" width="90">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.order_status == 0 ? 'danger' : 'success'"
                disable-transitions
                >{{ scope.row.order_status == 0 ? "未开启" : "已开启" }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            label="操作"
            width="auto"
            fixed="right"
            min-width="200px"
          >
            <template slot-scope="scope">
              <i
                class="el-icon-edit"
                @click="handleEdit(scope.row.id)"
                style="margin-left: 20px"
                >编辑</i
              >
              <i
                class="el-icon-close"
                @click="handleDel(scope.row.id)"
                style="margin-left: 20px;color:red"
                >删除</i
              >
            </template>
          </el-table-column>
        </el-table>

        <div style="margin-top: 10px">
          <template>
            <div class="block">
              <el-pagination
                layout="prev, pager, next"
                :current-page="tables.currentPage"
                :total="tables.total"
                @current-change="handleCurrentChange"
              />
            </div>
          </template>
        </div>
      </el-row>

      <!--    表单提交-->
      <el-dialog
        :title="dialog.title"
        :visible.sync="dialog.centerDialogVisible"
        :close-on-click-modal="false"
        width="65%"
        @closed="resetForm"
      >
        <el-form
          ref="dataForm"
          :inline="true"
          :model="formData"
          label-position="right"
          label-width="100px"
          style="padding-left: 40px"
        >
          <el-form-item label="标题" prop="title">
            <el-input v-model="formData.title" style="width: 300px" />
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input v-model="formData.content" style="width: 300px" />
          </el-form-item>
          <el-form-item label="订单状态" prop="status">
            <el-select
              v-model="formData.status"
              class="filter-item"
              placeholder="是否显示"
              style="width: 300px"
            >
              <el-option :key="1" label="显示" :value="1" />
              <el-option :key="0" label="隐藏" :value="0" />
            </el-select>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="resetForm">取 消</el-button>
          <el-button v-if="this.dialog.title == '编辑'" @click="edit"
            >确定</el-button
          >
          <el-button v-if="this.dialog.title == '新增'" @click="add"
            >添加</el-button
          >
        </span>
      </el-dialog>
      <!--    表单提交-->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      tables: {
        tableKey: 0,
        listLoading: false,
        currentPage: 1,
        pageSize: 10,
        total: 1,
        tableData: [],
      },
      dialog: {
        centerDialogVisible: false,
      },
      formData: {
        id: "",
        titel: "",
        content: "",
        status: 1,
      },
    };
  },
  
  created() {
    this.getList();
  },
  
  methods: {
    rowClass() {
      return "background:#f2f2f2;color:#4d4d4d";
    },
    getList() {
      this.tables.listLoading = true;
      const data = {
        pageSize: this.tables.pageSize,
        pageNum: this.tables.currentPage,
      };
      this.$fecth.post("system_message/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.tables.listLoading = false;
          this.tables.tableData = data.lists;
          this.tables.pageSize = data.pageSize;
          this.tables.total = data.total;
          this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleAdd() {
      this.dialog.title = "新增";
      this.dialog.centerDialogVisible = true;
    },
    add() {
      const data = {
        title: this.formData.title,
        content: this.formData.content,
        status: this.formData.status,
      };
      this.$fecth.post("system_message/add", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.getList();
          this.resetForm();
          this.showTips(1, msg);
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleEdit(id) {
      this.dialog.title = "编辑";
      this.formData.id = id;
      const data = {
        id: id,
      };

      this.$fecth.post("system_message/getDataById", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.formData = data;
          this.dialog.centerDialogVisible = true;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    edit() {
      const data = {
        id: this.formData.id,
        title: this.formData.title,
        content: this.formData.content,
        status: this.formData.status,
      };
      this.$fecth.post("system_message/edit", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.resetForm();
          this.getList();
          this.showTips(1, msg);
        } else {
          this.showTips(0, msg);
        }
      });
    },

    handleDel(id) {
      const data = {
        id: id,
      };
      this.$fecth.post("system_message/delete", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, "删除成功");
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    resetForm() {
      this.formData = {
        id: "",
        name: "",
        img: "",
        brief: "",
        original_price: "",
        current_price: "",
        desc: "",
        status: 1,
      };
      this.dialog.centerDialogVisible = false;
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    handleCurrentChange(val) {
      this.tables.currentPage = val;
      this.getList();
    },
  },
};
</script>